import { createGlobalStyle } from 'styled-components';
import { accent } from 'constants/theme';
import Tungsten2 from '../static/Tungsten-Semibold.woff2';
import Tungsten from '../static/Tungsten-Semibold.woff';
import Roboto from '../static/RobotoMono-Regular.woff';
import Roboto2 from '../static/RobotoMono-Regular.woff2';
import Retro from '../static/RetroComputer.woff';
import Retro2 from '../static/RetroComputer.woff2';
import BDRmono from '../static/BDRmono2006.woff';
import BDRmono2 from '../static/BDRmono2006.woff2';
import GTAmericaBoldItalic from '../static/GTAmerica-BoldItalic.woff';
import GTAmericaBoldItalic2 from '../static/GTAmerica-BoldItalic.woff2';
import GTAmericaLightItalic from '../static/GTAmerica-LightItalic.woff';
import GTAmericaLightItalic2 from '../static/GTAmerica-LightItalic.woff2';
import GTAmericaMedium from '../static/GTAmerica-Medium.woff';
import GTAmericaMedium2 from '../static/GTAmerica-Medium.woff2';
import FolioMedium from '../static/Folio-Medium.woff';
import FolioMedium2 from '../static/Folio-Medium.woff2';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  @font-face {
    font-family: 'Folio';
    src: url(${FolioMedium2}) format('woff2'),
        url(${FolioMedium}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'GT America';
    src: url(${GTAmericaBoldItalic2}) format('woff2'),
        url(${GTAmericaBoldItalic}) format('woff');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'GT America';
    src: url(${GTAmericaMedium2}) format('woff2'),
        url(${GTAmericaMedium}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
      font-family: 'GT America';
      src: url(${GTAmericaLightItalic2}) format('woff2'),
          url(${GTAmericaLightItalic}) format('woff');
      font-weight: 300;
      font-style: italic;
  }


  @font-face {
    font-family: 'Tungsten';
    src: url(${Tungsten2}) format('woff2'),
        url(${Tungsten}) format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${Roboto2}) format('woff2'),
        url(${Roboto}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Retro';
    src: url(${Retro2}) format('woff2'),
        url(${Retro}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'BDRmono';
    src: url(${BDRmono2}) format('woff2'),
        url(${BDRmono}) format('woff');
    font-weight: normal;
    font-style: normal;
  }




  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    overflow-x: hidden;
  }

  body {
    font-family: 'Folio';
    line-height: 1;
    font-size: 1.6rem;
    color: #000;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: ${accent};
  }

  pre {
    display: block;
    padding: 2rem;
    margin-top: 4rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 5px;
    color: ${accent};
    border: 1px solid #ddd;
    font-family: "SFMono-Regular",Consolas,"Liberation Mono",Menlo,Courier,monospace;
  }

  video {
    max-width: 100%;
  }

  p {
    margin-bottom: 2rem;
  }

  textarea,
  input.text,
  input[type="text"],
  input[type="button"],
  input[type="submit"],
  .input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
  }
`;
