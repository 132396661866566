/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {
  Wrapper,
  NLink,
  Nav,
  Logo,
  SocialList,
  RegLink,
  FooterLogo,
} from './footer.css';
import Icon from '../../../static/symbol.svg';
import Instagram from '../../../static/instagramBlack.svg';
import Twitter from '../../../static/twitterBlack.svg';
import Facebook from '../../../static/facebookBlack.svg';
import Spotify from '../../../static/spotify.svg';
import Apple from '../../../static/apple.svg';
import Amazon from '../../../static/amazon.svg';
import Youtube from '../../../static/youtube.svg';

class Footer extends Component {
  state = {
    showModal: false,
  };

  render() {
    return (
      <Fragment>
        <Wrapper>
          <SocialList>
            <li>
              <a
                href="https://instagram.com/lauvsongs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Instagram} alt="Instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/lauvsongs"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Twitter} alt="Twitter" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/lauvsongs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Facebook} alt="Facebook" />
              </a>
            </li>
            <li>
              <a
                href="https://open.spotify.com/artist/5JZ7CnR6gTvEMKX4g70Amv?si=90zJQCJlQa6QhGxLfvCcHQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Spotify} alt="Spotify" />
              </a>
            </li>
            <li>
              <a
                href="https://itunes.apple.com/us/artist/lauv/982612996"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Apple} alt="Apple Music" />
              </a>
            </li>
            <li>
              <a
                href="https://www.amazon.com/Lauv/e/B075JNXHJ1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Amazon}
                  alt="Amazon"
                  style={{ position: 'relative', top: '2px' }}
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCfLdIEPs1tYj4ieEdJnyNyw"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Youtube} alt="Youtube" />
              </a>
            </li>
          </SocialList>

          <Nav>
            <li>
              <RegLink href="http://mybluethoughts.world">
                My Blue Thoughts
              </RegLink>
            </li>
            <li>
              <RegLink href="https://breakingmodernloneliness.com/">
                Breaking Modern Loneliness
              </RegLink>
            </li>
            <li>
              <RegLink href="https://shop.lauvsongs.com/">Store</RegLink>
            </li>
            <li>
              <RegLink href="https://my.community.com/lauv">
                SEND A TEXT
              </RegLink>
            </li>
          </Nav>

          <FooterLogo>
            <a
              href="https://lauvsongs.com/privacy-policy.html"
              target="_blank"
              rel="noopener noreferrer"
              className="privacyPolicyLink"
            >
              Privacy Policy
            </a>
          </FooterLogo>
        </Wrapper>
      </Fragment>
    );
  }
}

export default Footer;
