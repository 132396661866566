// eslint-ignore
import React from 'react';
import Carousel from 'nuka-carousel';
import Div100vh, { use100vh } from 'react-div-100vh';
import { Nav as NavWrapper, BG } from './hero.css';
import Nav from '../nav/nav';
// import Lauv from '../../../static/hero-lauv.jpg';
// import Together from '../../../static/hero-together.jpg';
// import Lauv2 from '../../../static/hero-lauv2.jpg';

const Hero = () => {
  const height = use100vh();
  return (
    <Div100vh>
      <NavWrapper className="wrapper">
        <Nav />
      </NavWrapper>
      <Carousel
        wrapAround
        swiping={false}
        dragging={false}
        renderBottomCenterControls={() => null}
        renderCenterLeftControls={() => null}
        renderCenterRightControls={() => null}
        style={{
          backgroundColor: '#15325a',
        }}
      >
        <BG dynamicHeight={height}>
          <div className="albumContainer bottomAlign bottomMargin">
            <a
              className="heroCta"
              href="#tour"
              rel="noopener noreferrer"
              style={{ position: 'relative', zIndex: 2000 }}
            >
              Tickets
            </a>
          </div>
        </BG>
      </Carousel>
    </Div100vh>
  );
};

export default Hero;
