import React, { Component, Fragment } from 'react';
import { Rainbow, Box, FormFields, Terms } from './mailinglist.css';
import PrivacyPolicy from '../privacy-policy';

class MailingList extends Component {
  state = {
    hideMobile: true,
    showModal: false,
  };

  toggleForm = e => {
    e.preventDefault();
    this.setState(state => {
      return {
        hideMobile: !state.hideMobile,
      };
    });
  };

  handleOpenModal = e => {
    e.preventDefault();
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <Fragment>
        <Rainbow />
        <Box>
          <form
            action="https://lauvsongs.us15.list-manage.com/subscribe/post?u=dfb00ff866a8876f6559dd970&amp;id=cb9c16e029"
            method="post"
          >
            <div
              style={{
                display: 'flex',
              }}
            >
              <button onClick={this.toggleForm} className="signUptitle">
                Sign Up For Updates
              </button>
              <Terms className="terms">
                <button onClick={this.handleOpenModal} className="termsBtn">
                  Terms
                </button>
              </Terms>
            </div>
            <FormFields hideMobile={this.state.hideMobile}>
              <input type="text" name="EMAIL" placeholder="Email Address" />
              <input type="text" name="PHONE" placeholder="Phone Number" />
              <input type="text" name="ZIPCODE" placeholder="Zip Code" />
              <input type="submit" value="Sign Up" />
            </FormFields>
          </form>
          <PrivacyPolicy
            showModal={this.state.showModal}
            handleCloseModal={this.handleCloseModal}
          />
        </Box>
        <Rainbow />
      </Fragment>
    );
  }
}

export default MailingList;
