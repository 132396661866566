import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Footer from 'components/footer';
import Video from 'components/video';
import Hero from 'components/hero';
import MailingList from 'components/mailinglist';
import Tour from 'components/tour/tour';
import GDPR from 'components/gdpr';
import { graphql } from 'gatsby';

const Index = () => (
  <Layout>
    <Hero />
    <MailingList />
    <Video />
    <Tour />
    <Footer />
    <GDPR />
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
