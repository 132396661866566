import styled, { css } from 'styled-components';

export const Terms = styled.div`
  margin-left: 10px;
  margin-bottom: 15px;
  button {
    font-family: 'Folio';
    font-weight: 500;
    font-style: normal;
  }
`;
export const Box = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 20px 35px;
  font-size: 1.4em;
  box-sizing: border-box;

  @media (max-width: 700px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .termsBtn {
    border: none;
    background: none;
    padding: none;
    text-transform: uppercase;
    padding: 0;
    text-transform: uppercase;
    font-size: 0.4em;
    color: #c1c1c1;
  }

  form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    input[type='submit'] {
      background: #fff;
      color: #000;
      font-family: 'Folio';
      font-weight: 500;
      font-style: normal;
      font-size: 0.7em;
      text-transform: uppercase;
      border: 1px solid #000;
      padding: 10px 14px;
      cursor: pointer;
      &:hover {
        background-color: #000;
        color: #fff;
      }
    }

    input[type='text'] {
      font-family: 'Folio';
      font-size: 0.6em;
      padding: 1px 0;
      border: none;
      border-bottom: 1px solid #000;
      width: 140px;
      text-transform: uppercase;
      outline: none;
      &:first-child {
        margin-left: 0;
      }
      &::placeholder {
        color: #000;
      }
      @media (max-width: 700px) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  .signUptitle {
    text-transform: uppercase;
    border: none;
    padding: 0;
    background: none;
    outline: none;
    font-size: 0.7em;
    text-transform: uppercase;
    font-family: 'Folio';
  }
`;

export const FormFields = styled.div`
  display: flex;
  max-width: 754px;
  width: 100%;
  @media (max-width: 700px) {
    flex-direction: column;
    margin-top: 20px;
    ${({ hideMobile }) =>
      hideMobile &&
      css`
        display: none;
      `};
  }
  justify-content: space-between;
`;

export const Rainbow = styled.div`
  width: 100%;
  height: 2px;
  background-color: #fff;
`;
