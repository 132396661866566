import React from 'react';
import YoutubeEmbedVideo from 'react-youtube-embed';
import styled from 'styled-components';

const Video = () => (
  <Container>
    <div>
      <YoutubeEmbedVideo id="yQ7Xp6dOCCk" />
    </div>
  </Container>
);

export default Video;

const Container = styled.div`
  width: 100%;
  max-width: 1260px;
  padding: 0 15px;
  margin: 25px auto;
  box-sizing: border-box;

  div {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;
