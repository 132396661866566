import styled from 'styled-components';
import { Link } from 'react-scroll';
import PosterImage from '../../../static/footer-poster.jpg';

export const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  text-align: center;
  padding: 75px 0 100px;

  .privacyPolicyLink {
    font-family: 'Folio';
    font-size: 0.8em;
    font-weight: 300;
    color: #15325a;
    &:hover {
      color: #000;
    }
  }
`;

export const Nav = styled.ul`
  li {
    display: inline-block;
    margin: 0 10px;
  }
`;

export const NLink = styled(Link)`
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 180ms ease-out;
  font-size: 0.8em;
  font-family: 'Folio';
  font-weight: 300;
  font-style: italic;

  &:hover {
    color: #f922ab;
  }
`;

export const RegLink = styled.a`
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 180ms ease-out;
  font-size: 0.8em;
  font-family: 'Folio';
  font-weight: 300;

  &:hover {
    color: #f922ab;
  }
`;

export const Logo = styled.img`
  margin: 45px 0 0 0;
`;

export const SocialList = styled.ul`
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    display: inline-block;
    margin: 0 20px;
    @media (max-width: 700px) {
      margin: 0 10px;
    }
  }

  a {
    transition: opacity 180ms ease-out;
    line-height: 1.8;
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const FooterLogo = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  margin: 0 auto;

  a {
    margin: 40px 0 0;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    font-size: 1.4em;
    font-size: 0.9em;
    font-family: 'Roboto';
    font-weight: normal;
  }

  button {
    margin-top: 20px;
    background: none;
    color: #fff;
    border: none;
    font-family: 'Tungsten';
    font-weight: 600;
    font-style: normal;
    text-transform: uppercase;
    font-size: 1.1em;
  }
`;

export const Poster = styled.div`
  width: 100vw;
  &:after {
    background: #fff url(${PosterImage}) no-repeat;
    background-size: cover;
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;
