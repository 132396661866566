import styled, { css } from 'styled-components';
import Logo from '../../../static/Logo.png';
import HeroBG from '../../../static/hero-tour.jpg';
import HeroBG2 from '../../../static/hero2.jpg';
// import HeroTour from '../../../static/hero-tour.jpg';
import HeroType from '../../../static/hero-text.png';

export const AlbumType = styled.div`
  max-width: 665px;
  width: 100%;
  &:after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 18.83333333%;
    background: transparent url(${HeroType}) no-repeat;
    background-size: cover;
  }
`;

export const Nav = styled.div`
  margin-top: 50px;
  width: 100%;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  z-index: 2000;
  padding: 0 20px;
  left: 0;
  right: 0;
  box-sizing: border-box;
  .Logo {
    max-width: 218.79px;
    height: 77px;
    background: transparent url(${Logo}) no-repeat;
    background-size: cover;
    text-indent: -9999px;
    transform: invert(1);
  }

  @media (max-width: 700px) {
    margin-top: 3vh;
    a:first-child {
      margin-left: 0;
    }
  }
`;

export const BG = styled.div`
  position: relative;
  width: 100%;
  height: ${({ dynamicHeight }) => dynamicHeight}px;
  top: 0;
  background-color: #fff;
  a {
    font-family: 'Folio';
  }

  .albumContainer {
    width: 100vw;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-height: 600px) {
      height: 100%;
    }

    &.photoBlock {
      margin-top: 50px;
    }

    &.bottomAlign {
      justify-content: flex-end;
      display: flex;

      .bottomMargin {
        z-index: 1000;
        margin-bottom: 10vh;
      }
    }
  }

  .photoContainer {
    display: flex;
    width: calc(100% - 80px);
    height: auto;
    justify-content: space-around;

    @media (max-width: 600px) {
      width: calc(100% - 15px);
    }

    img {
      width: calc(100% / 3 - 80px);
      object-fit: contain;
      @media (max-width: 600px) {
        width: calc(100% / 2 - 15px);
        box-shadow: none;
      }
    }

    .hideMobile {
      @media (max-width: 600px) {
        display: none;
      }
    }
  }

  .promoText {
    font-family: 'GT America';
    text-align: center;
    color: #fff;
    font-size: 35px;
    @media (max-width: 600px) {
      font-size: 20px;
    }
  }

  .heroCta {
    margin-top: 30px;
    margin-bottom: 50px;
    font-family: 'Folio';
    font-weight: 500;
    font-style: normal;
    border: 1px solid #fff;
    background-color: #fff;
    color: #000;
    text-decoration: none;
    padding: 16px 30px;
    transition: all 180ms ease-out;
    &:hover {
      color: #15325a;
    }
    @media (max-width: 750px) {
      padding: 13px 20px;
      font-size: 1.2rem;
    }
  }

  &:after {
    position: absolute;
    width: 100%;
    margin-inline: auto;
    height: ${({ dynamicHeight }) => dynamicHeight}px;
    display: block;
    content: '';
    background: #fff url(${({ secondary }) => (secondary ? HeroBG2 : HeroBG)})
      no-repeat;
    background-position: center;
    background-size: contain;
  }
`;

export const Copy = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  max-width: 50%;
  height: 100%;
  padding: 0 70px;
  box-sizing: border-box;
  font-family: 'BDRmono';
  font-size: 1rem;

  @media (max-width: 750px) {
    padding: 0 30px;
    width: 100%;
    max-width: none;
  }
`;

export const Txt = styled.div`
  font-size: 5em;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 24px;
  white-space: nowrap;
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);

  @media (min-width: 1200px) {
    font-size: 5em;
  }

  @media (max-width: 600px) {
    font-size: 8vw !important;
  }

  @media (max-width: 330px) {
    font-size: 6.4vw;
  }

  ${({ cta }) =>
    cta &&
    css`
      font-family: 'Retro';
      position: absolute;
      bottom: 40px;
      font-size: 22px;

      @media (max-width: 750px) {
        font-size: 4.5vw !important;
      }

      @media (min-width: 1200px) {
        font-size: 22px;
      }
    `}
`;

export const MobilePromo = styled.div`
  display: none;
  @media (max-width: 700px) {
    display: block;
    background: linear-gradient(
      89.91deg,
      #6530cf 0.64%,
      #1032f2 23.92%,
      #83e460 43.07%,
      #efe755 61.69%,
      #df7c36 80.84%,
      #d24237 99.98%
    );
  }

  .orderWrapper {
    padding: 30px 0;
    text-align: center;
    color: #fff;
  }

  .orderTitle {
    font-family: 'GT America';
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
  }

  .orderBtns {
    display: flex;
    justify-content: center;
    margin-top: 18px;
    a {
      margin: 0 10px;
      font-family: 'GT America';
      font-weight: bold;
      font-style: italic;
      background: #000;
      border-radius: 30px;
      padding: 10px;
      color: #fff;
      text-decoration: none;
      font-size: 0.65em;
      display: inline-block;
      max-width: 250px;
      width: calc(50% - 30px);
      text-transform: uppercase;
      transition: all 180ms ease-out;
      @media (max-width: 700px) {
        /* width: 100%;
        color: #000;
        padding: 0; */
      }
      &:hover {
        background: linear-gradient(
          89.91deg,
          #6530cf 0.64%,
          #1032f2 23.92%,
          #83e460 43.07%,
          #efe755 61.69%,
          #df7c36 80.84%,
          #d24237 99.98%
        );
      }
    }
  }
`;
