import React, { Component } from 'react';
import { Container, Content, CloseBtn } from './gdpr.css';
import { setCookie, getCookie } from '../../utils';

class GDPR extends Component {
  state = {
    cookie: true,
  };

  componentDidMount() {
    const gdprCookie = getCookie('_gdpr-local');

    if (gdprCookie === null) {
      this.setState({
        cookie: false,
      });
    }
  }

  closeGDPR = () => {
    setCookie('_gdpr-local', '1', 1825);
    this.toggleGDPR();
  };

  toggleGDPR = () => {
    this.setState({
      cookie: true,
    });
  };

  render() {
    if (this.state.cookie) {
      return null;
    }

    return (
      <Container>
        <Content>
          THIS WEBSITE USES COOKIES. BY CONTINUING TO BROWSE THE WEBSITE, YOU
          ARE AGREEING TO OUR USE OF COOKIES. LAUV AND OUR PARTNERS OPERATE
          WORLDWIDE AND USE COOKIES FOR REASONS SUCH AS ANALYTICS, SITE
          OPERATIONS, PERSONALIZATION AND SERVING ADVERTISEMENTS. TO LEARN MORE,
          INCLUDING HOW TO MANAGE COOKIES,{' '}
          <a href="/CookiePolicy.html" target="_blank" rel="nofollow">
            CLICK HERE
          </a>
          .
        </Content>
        <CloseBtn onClick={this.closeGDPR}>
          <svg width="10" height="10" viewBox="0 0 10 10">
            <polygon points="5 4.214 1.072 .286 .286 1.072 4.214 5 .286 8.928 1.072 9.714 5 5.786 8.928 9.714 9.714 8.928 5.786 5 9.714 1.072 8.928 .286" />
          </svg>
        </CloseBtn>
      </Container>
    );
  }
}

export default GDPR;
