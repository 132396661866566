import React from 'react';
import { Container, Title } from './tour.css';

function Tour() {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://widget.seated.com/app.js';
    document.body.appendChild(script);
  }, []);

  return (
    <Container name="tour" id="tour">
      <Title>Tour Dates</Title>
      <div
        id="seated-55fdf2c0"
        data-artist-id="4d9001e0-a5dd-4df9-9600-13b7f6cb6864"
        data-css-version="2"
      />
    </Container>
  );
}

export default Tour;
