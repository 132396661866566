import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import './privacy-policy.css';

function PrivacyPolicy({ showModal, handleCloseModal }) {
  return (
    <ReactModal
      isOpen={showModal}
      contentLabel="Privacy Policy"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.45)',
        },
        content: {
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '0',
          width: 'calc(92% - 40px)',
          outline: 'none',
          padding: '20px',
          maxWidth: '500px',
          maxHeight: '200px',
          margin: 'auto',
        },
      }}
    >
      <button onClick={handleCloseModal} className="closeBtn">
        <svg width="18" height="18" viewBox="0 0 18 18">
          <polygon points="9 7.586 1.929 .515 .515 1.929 7.586 9 .515 16.071 1.929 17.485 9 10.414 16.071 17.485 17.485 16.071 10.414 9 17.485 1.929 16.071 .515" />
        </svg>
      </button>
      <div className="content">
        <div className="privacyPolicy">
          By signing up, I agree to receive personalized marketing messages and
          updates about Lauv based on my information, interests, activities,
          website visits and device data consistent with the{' '}
          <a
            href="https://lauvsongs.com/privacy-policy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          . I acknowledge that I can opt-out at any time by emailing{' '}
          <a href="mailto:privacy@lauvsongs.com">privacy@lauvsongs.com</a>.
        </div>
      </div>
    </ReactModal>
  );
}

PrivacyPolicy.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

export default PrivacyPolicy;
