import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0.15);
  padding: 20px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 100;
  font-size: 0.8em;
  line-height: 1.3;
  font-family: 'GT America';
  font-weight: 300;
  font-style: italic;
  box-sizing: border-box;

  & > div {
    box-sizing: border-box;
  }

  a {
    color: #000;
    text-decoration: underline;
  }
`;

export const Content = styled.div`
  width: 100%;

  @media (max-width: 700px) {
    font-size: 0.8em;
  }
`;

export const CloseBtn = styled.button`
  padding: 10px;
  display: inline-block;
  text-transform: uppercase;
  margin-left: 15px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;

  svg {
    position: relative;
    top: 1px;
  }
`;
