import styled from 'styled-components';

export const Container = styled.section`
  background-color: #fff;
  padding-bottom: 100px;
  max-width: 1280px;
  padding: 0 1.5rem;
  margin: 0 auto;

  .seated-no-events {
    text-align: center;
  }
`;

export const Title = styled.div`
  text-transform: uppercase;
  font-size: 2em;
  padding: 50px 0 75px;
  text-align: center;
  color: black;
`;
